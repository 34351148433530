<template>
  <div class="myInfo-container">
    <div class="content">
      <van-cell-group v-if="true" :border="false">
        <van-cell
          title="个人身份"
          :class="!selectIdentity.comId ? 'active' : ''"
          @click="handleSelectPersonal(userInfo.comId)"
        >
          <template v-if="!selectIdentity.comId" #default>
            <van-icon
              :name="require('@/assets/imgs/ios2x_路径 2@2x.png')"
              class="select-icon"
            />
          </template>
        </van-cell>
        <van-cell-group :border="false">
          <van-cell
            :title="item.comName"
            v-for="(item, index) in myInfo.companyInfoVoList"
            :key="index"
            :class="selectIdentity.comId === item.comId ? 'active' : ''"
            @click="handleSelectCompany(item)"
          >
            <template v-if="selectIdentity.comId === item.comId" #default>
              <van-icon
                :name="require('@/assets/imgs/ios2x_路径 2@2x.png')"
                class="select-icon"
              />
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell
          title="创建企业"
          class="create"
          :icon="require('@/assets/imgs/形状结合 2@2x.png')"
          @click="handleCreateCompany"
        />
      </van-cell-group>
      <!-- <div>
        <span>
          <van-icon :name="require('@/assets/imgs/形状结合 2@2x.png')" />
          创建企业
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapState({
        myInfo: state => state.user.myInfo,
        userInfo: state => state.user.userInfo,
      }),
      // eslint-disable-next-line vue/return-in-computed-property
      selectIdentity() {
        let com = {}
        this.myInfo.companyInfoVoList.forEach(item => {
          if (item.comId === this.userInfo.comId) {
            com = { ...item }
          }
        })
        return com
      },
    },
    methods: {
      async handleSelectCompany(val) {
        if (val.comId === this.userInfo.comId) return
        await this.$store.dispatch('updateCurrentIdentity', val.comId)
        this.$router.go(-1)
      },
      async handleSelectPersonal(val) {
        if (val.comId === this.userInfo.comId) return
        await this.$store.dispatch('updateCurrentIdentity', '')
        this.$router.go(-1)
      },
      handleCreateCompany() {
        this.$store.commit('setComSignUrl', '/My')
        this.$router.push('CreateCompany')
      },
    },
  }
</script>

<style lang="less" scoped>
  .myInfo-container {
    width: 100%;
    height: 100%;
    .content {
      padding: 15px 0;
      .active {
        .van-cell__title {
          color: #677283;
        }
        .van-cell__title {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #1676ff;
        }
      }
      .create {
        .van-icon {
          line-height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
        }
        .van-cell__title {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #1676ff;
        }
      }
    }
    .van-cell {
      padding: 12px 16px;
    }
    .van-cell__value {
      color: #111a34;
    }
    .van-icon-arrow::before {
      color: #111a34;
    }
    .van-cell__title {
      color: #677283;
    }
  }
</style>
